<script setup>
import {useRouter} from "vue-router";

const router = useRouter();

const handleJump = (route) => {
    router.push(route);
};
</script>

<template>
    <div class="wrap">
        <img src="@/assets/homeTopLogo.png" alt="" class="homeTopLogo">
        <nav>
            <div class="item" :class="{active:router.currentRoute.value.name === 'home'}" @click="handleJump('/')">Home</div>
            <div class="item" :class="{active:router.currentRoute.value.name === 'down'}" @click="handleJump('/down')">Download</div>
            <img src="@/assets/appName.png" alt="" class="appName">
            <div class="item" :class="{active:router.currentRoute.value.name === 'about'}" @click="handleJump('/about')">About US</div>
            <div class="item" :class="{active:router.currentRoute.value.name === 'policy'}" @click="handleJump('/policy')">Terms &Policy</div>
        </nav>
    </div>
</template>

<style scoped lang="scss">
.wrap {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1000;

    .homeTopLogo {
        width: 40px;
        height: 40px;
        margin: 10px 20px;
    }

    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 1354px;
        padding: 15px 136px;
        background-image: url('@/assets/nav.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 70px;

        .item {
            font-size: 18px;
            color: #8E8E8E;
            line-height: 25px;
            cursor: pointer;
        }

        .active {
            color: #522D06;
            font-weight: 700;
        }

        .appName {
            height: 24px;
        }
    }
}
</style>